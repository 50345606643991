<template>
    <div class="container">
        <!-- <div class="screen">
        <div class="input">
          <div>消息标题：</div>
          <el-input v-model="input" placeholder="输入内容"></el-input>
        </div>
        <div>
          状态：
          <el-select v-model="value" clearable placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
   
        <el-button type="primary" @click="getMessageList('nopage')">查询</el-button>
      </div> -->
        <div class="box">
            <div class="item" v-for="item in messageList" :key="item.id">
                <div class="party">{{ item.first_party_name }}（甲方） </div>
                <div><span class="contract-title">签约时间：</span>{{ item.sign_date }}</div>
                <div><span class="contract-title">服务开始时间：</span>{{ item.start_date }}</div>
                <div><span class="contract-title">服务结束时间：</span>{{ item.end_date }}</div>
                <div><span class="contract-title">审核状态：</span>
                    <span v-if="item.audit_status == 1">待审核</span>
                    <span v-if="item.audit_status == 2">审核通过</span>
                    <span v-if="item.audit_status == 3">审核不通过</span>
                    <span v-if="item.audit_status == 4">已失效</span>
                </div>
                <div><span class="contract-title">备注：</span>{{ item.remark || '无' }}</div>
                <el-button v-if="item.audit_status == 2" type="primary" icon="el-icon-document" @click="getMessageDetail(item)"  size="small">查看合同附件</el-button>
                <!-- <el-button v-else type="primary" @click="getMessageDetail(item)"  size="small">重新审核</el-button> -->
            </div>
            <div v-if="messageList.length % 3 < 2" style="width:30%"></div>
            <div v-if="messageList.length % 3 < 3" style="width:30%"></div>
        </div>
    </div>
</template>
  
<script>

export default {
    name: 'message',
    data() {
        return {
            dialogVisible: false,
            value1: [],
            input: '',
            options: [
                {
                    value: '0',
                    label: '待查看'
                },
                {
                    value: '1',
                    label: '已查看'
                }
            ],
            value: '',
            messageList: [],
            total: 0,
            order_by: '',
            page: 1,
            limit: 10,
            unViewNum: 0,
            messageDetail: {}

        }
    },
    created() {
        this.getMessageList()
    },
    computed: {
        shopInfo() {
            return JSON.parse(window.localStorage.getItem('shopInfo')) || {}
        }
    },
    methods: {
        getMessageDetail(data) {
            window.open(data.contract_enclosure_img)
        },
        getMessageList(e) { // 获取消息列表
            this.$http2.get('/merapi/shop/electronic/signature/list').then(res => {
                console.log(res.data.data)
                this.messageList = res.data.data

            }).catch(err => {
                return this.$message.error(err)
            })
        }
    }
}
</script>
  
<style scoped lang="less">
.container {
    .el-button {
        background-color: #0078fb;
    }
    .box {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .item {
            background-color: #fff;
            width: 30%;
            display: flex;
            margin-bottom: 20px;
            flex-direction: column;
            // align-items: center;
            font-size: 16px;
            color: #222;
        
            height: 434px;
            padding: 20px 0;
            justify-content: space-around;
            box-sizing: border-box;
            div {
                text-align: left;
            }
            .el-button {
                width: 200px;
                margin: 0 auto;
            }
            .party {
                font-size: 18px;
                color: #101010;
                font-weight: 600;
                text-align: center;
            }
            .contract-title {
                display: inline-block;
                width: 50%;
                text-align: right;
            }
        }
    }

    .screen {
        display: flex;
        align-items: center;
        // justify-content: space-between;
        font-size: 19px;

        &>div {
            margin-right: 40px;
        }

        .input {
            display: flex;
            align-items: center;

            div {
                width: 100px;
            }

            .el-input {
                width: 200px;
            }
        }
    }

    .table {
        margin: 0 0 30px 0;
    }

    .el-dialog {
        .el-dialog__header {
            display: flex;
            justify-content: center;
        }

        .content {
            text-align: center;

            .title {
                font-size: 22px;
                font-weight: 700;
                margin-bottom: 20px;
            }

            .message {
                font-size: 20px;
            }
        }
    }

    .pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 30px 0;
    }
}
</style>
  